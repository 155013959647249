import { render, staticRenderFns } from "./ticketDetail.vue?vue&type=template&id=55f89d3a&scoped=true&"
import script from "./ticketDetail.vue?vue&type=script&lang=js&"
export * from "./ticketDetail.vue?vue&type=script&lang=js&"
import style0 from "./ticketDetail.vue?vue&type=style&index=0&id=55f89d3a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f89d3a",
  null
  
)

export default component.exports